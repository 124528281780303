import React, {useState} from "react";
import {
    Card,
    Divider,
    Stack,
    TextField,
    Typography,
    Button,
    FormControl,
    MenuItem,
    Select, IconButton, Tooltip, Box, CircularProgress
} from "@mui/material";
import {CopyAll, OpenInNew} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../../const";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Parameters = ({player}) => {
    const actual = player.data()
    const [params, setParams] = useState(actual)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleSetParams = (id, value) => {
        setParams(prevstate => ({...prevstate, [id]: value}))
    }

    const updateParams = async (e) => {
        e.preventDefault()
        setUpdating(true)
        await axios.put(`${API_URL}/players/${player.id}/update`, params)
            .then(() => setSuccess(true))
            .catch(() => setError(true))
        setUpdating(false)
    }

    const reset = () => {
        setError(false)
        setSuccess(false)
    }

    return (
        <form onSubmit={updateParams}>
            <Snackbar open={error || success} autoHideDuration={6000} onClose={reset}>
                <Alert onClose={reset} severity={error ? 'error' : 'success'} sx={{width: '100%'}}>
                    {error ? "C'è stato un problema, riprova" : "Parametri modificati con successo"}
                </Alert>
            </Snackbar>
            <Stack spacing={1.5}>
                <Card elevation={5} sx={{p: 1.5}}>
                    <Typography>
                        Nome :
                    </Typography>
                    <TextField required placeholder={'Inserisci un nome'} fullWidth variant={"standard"}
                               value={params.name} onChange={(e) => handleSetParams('name', e.target.value)}/>
                </Card>
                <Card elevation={5} sx={{p: 1.5}}>
                    <Typography>
                        Id: <b>{player.id}</b>
                    </Typography>
                    <Divider sx={{my: 1}}/>
                    <Stack direction={'row'} alignItems={"center"} spacing={1}>
                        <Typography>
                            Link: <b>https://players.plesh.co/{player.id}</b>
                        </Typography>
                        <Tooltip title={'Apri in una nuova scheda'}>
                            <IconButton component={"a"} target={"_blank"}
                                        href={`https://players.plesh.co/${player.id}`}>
                                <OpenInNew/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Copia'}>
                            <IconButton onClick={() => {
                                navigator.clipboard.writeText(`https://players.plesh.co/${player.id}`)
                            }}>
                                <CopyAll/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Card>
                <Card elevation={5} sx={{p: 1.5}}>
                    <Typography>
                        Servizio :
                    </Typography>
                    <FormControl fullWidth variant={"standard"} required>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={params.service}
                            onChange={(e) => handleSetParams('service', e.target.value)}
                        >
                            <MenuItem value={'ivs'}>Ivs</MenuItem>
                            <MenuItem value={'multilanguage'}>Multilingua</MenuItem>
                        </Select>
                    </FormControl>
                </Card>
                <Card elevation={5} sx={{p: 1.5}}>
                    <Typography variant={"h6"}>
                        Stream URL
                    </Typography>
                    <Divider sx={{my: 1}}/>
                    <Typography fontWeight={"bold"} sx={{wordBreak: 'break-all'}}>
                        Main
                    </Typography>
                    <TextField required multiline fullWidth value={params.stream_url}
                               onChange={(e) => handleSetParams('stream_url', e.target.value)}/>
                </Card>
                {updating ? <Box sx={{textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    <Button type={'submit'} variant={'contained'} disabled={params === actual}>Salva</Button>}
            </Stack>
        </form>
    )
}
