import React from "react";
import {
    Box,
    Card,
    CardActionArea, CardActions,
    CardContent,
    CardMedia, IconButton,
    Stack,
    Typography,
    Menu,
    MenuItem, ListItemText, ListItemIcon
} from "@mui/material";
import {Link} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import {Archive, Delete, MoreVert, Unarchive} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../../const";

export const PlayerCard = ({id, player}) => {
    const [poster, loading, error] = useDocument(firestore.doc(`players/${id}/posters/${player.active_poster}`))
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const deletePlayer = async () => {
        await axios.put(`${API_URL}/players/${id}/delete`)
            .then(() => {
                handleClose()
            })
    }

    const archivePlayer = async () => {
        await axios.put(`${API_URL}/players/${id}/archive`)
            .then(() => {
                handleClose()
            })
    }

    return (
        <Card>
            {(!loading && !error && poster.data() && poster.data().link) &&
                <CardActionArea component={Link} to={`/control/${id}`}>
                    <CardMedia
                        component="img"
                        height="194"
                        image={poster.data().link}
                        alt="Poster"
                    />
                    <CardContent>
                        <Typography variant={"h6"}>
                            {player.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            }
            <CardActions>
                <Stack sx={{width: '100%'}} direction={'row'} justifyContent={"space-between"}>
                    <Stack direction={'row'} alignItems={'center'} sx={{width: '100%', height: '3rem'}}>
                        <Box sx={{pr: 1}}>
                            <div className={player.live ? "blob-live red" : "blob"}></div>
                        </Box>
                        <Box>
                            <Typography variant={'subtitle1'} fontWeight={'bold'}>
                                {player.live ? "Il player è live" : "Il player è in pausa"}
                            </Typography>
                        </Box>
                    </Stack>
                    {!player.live && <Box>
                        <IconButton onClick={handleClick}>
                            <MoreVert/>
                        </IconButton>
                    </Box>}
                </Stack>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={archivePlayer}>
                        <ListItemIcon>
                            {player.archived ? <Unarchive fontSize={"small"}/> : <Archive fontSize={"small"}/>}
                        </ListItemIcon>
                        <ListItemText>
                            {player.archived ? 'Ripristina' : 'Archivia'}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={deletePlayer}>
                        <ListItemIcon>
                            <Delete fontSize={"small"}/>
                        </ListItemIcon>
                        <ListItemText>
                            Elimina
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </CardActions>

        </Card>
    )
}
