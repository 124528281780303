import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Route, Routes} from 'react-router-dom'
import Live from "./components/PlayerContent/Live";
import {theme} from "./theme/theme";
import {PlayerContent} from "./pages/PlayerContent";
import {Home} from "./pages/Home";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import {Login} from "./pages/Login";
import {PlayerControl} from "./pages/PlayerControl";

function App() {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes>
                <Route path={'/login'} element={<Login/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/control/:id'} element={<PlayerControl/>}/>
                </Route>
                <Route path={'/:id'} element={<PlayerContent/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
