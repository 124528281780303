import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from "../../assets/logo-players.png";
import {Link} from "react-router-dom";
import {firestore} from "../../firebase/clientApp";

function PlayerAppBar() {

    return (
        <AppBar position="static">
            <Box sx={{px:2}}>
                <Toolbar disableGutters variant={"dense"}>
                    <Box sx={{width:'6rem', mr:2}} component={Link} to={'/'}>
                        <img src={logo} style={{width: '100%'}}/>
                    </Box>
                </Toolbar>
            </Box>
        </AppBar>
    );
}
export default PlayerAppBar;
