import React, {useEffect, useState} from "react";
import {
    Box,
    Grid,
    Button,
    CircularProgress,
    Card,
    Typography,
    Stack,
    CardContent,
    Dialog,
    DialogContent, DialogActions, DialogContentText, DialogTitle, useTheme
} from "@mui/material";
import {useParams} from "react-router-dom";
import PlayerAppBar from "../components/shared/PlayerAppBar";
import {firestore} from "../firebase/clientApp";
import {useDocument} from "react-firebase-hooks/firestore";
import {PlayArrow, Stop} from "@mui/icons-material";
import {Options} from "../components/PlayerContent/Options";
import axios from "axios";
import {API_URL} from "../const";

const ConfirmationDialog = ({live, open, handleSetOpen, id}) => {
    const [activating, setActivating] = useState(false)
    const theme = useTheme()

    const setLive = async () => {
        setActivating(true)
        await axios.put(`${API_URL}/players/${id}/start`)
            .then(() => {

            })
        setActivating(false)
    }

    const playPause = async () => {
        await setLive().then(() => handleSetOpen(false))
    }

    return(
        <Dialog open={open} onClose={() => handleSetOpen(false)}>
            <DialogContent>
                <DialogTitle>
                    Sei sicuro di voler <Box component={'span'} sx={{color:theme.palette.primary.main}}>{live ? "stoppare" : "avviare"}</Box> il player?
                </DialogTitle>
                <DialogActions sx={{gap:2, justifyContent:'center'}}>
                    <Box sx={{position:'relative'}}>
                        {activating && <Stack sx={{width:'100%', height:'100%'}} alignItems={"center"} justifyContent={'center'} position={'absolute'}>
                            <CircularProgress size={20}/>
                        </Stack>}
                        <Button variant={'contained'} disabled={activating} onClick={playPause}>Conferma</Button>
                    </Box>
                    <Button onClick={() => handleSetOpen(false)}>Annulla</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export const PlayerControl = () => {
    const {id} = useParams()
    const [player, loading, error] = useDocument(firestore.doc(`players/${id}`), {})
    const [open, setOpen] = useState(false)

    const handleSetOpen = (bool) => {
        setOpen(bool)
    }


    return (
        <Box>
            {!loading && !error && <ConfirmationDialog open={open} handleSetOpen={handleSetOpen} live={player.data().live} id={id}/>}
            <PlayerAppBar/>
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={12} md={8}>
                    <Box sx={{width: '100%'}}>
                        <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                            <iframe
                                src={`https://players.plesh.co/${id}`}
                                frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                            ></iframe>
                        </div>
                    </Box>
                    <Card sx={{mt: 1}}>
                        <CardContent>
                            {loading ?
                                <CircularProgress/>
                                :
                                <Stack direction={{xs: 'row'}} alignItems={"center"} spacing={3}>
                                    <Box position={'relative'}>
                                        <Button variant={"contained"}
                                                 startIcon={player.data().live ? <Stop/> : <PlayArrow/>}
                                                 onClick={() => handleSetOpen(true)}>
                                            {player.data().live ? 'Stop' : 'Play'}
                                        </Button>
                                    </Box>
                                    <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'} justifyContent={"space-between"}>
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <Box sx={{pr: 1}}>
                                                <div className={player.data().live ? "blob-live red" : "blob"}></div>
                                            </Box>
                                            <Box>
                                                <Typography variant={'subtitle1'} fontWeight={'bold'}>
                                                    {player.data().live ? "Il player è live" : "Il player è in pausa"}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Stack>

                                </Stack>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{height: '100%'}}>
                        {loading ?
                            <Stack sx={{width: '100%', height: '100%'}} justifyContent={"center"} alignItems={"center"}>
                                <CircularProgress/>
                            </Stack>
                            :
                            <CardContent>
                                <Options player={player}/>
                            </CardContent>
                        }
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
