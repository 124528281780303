import React from "react";
import {Box} from "@mui/material";
import Live from "../components/PlayerContent/Live";
import {useParams} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";

export const PlayerContent = () => {
    const {id} = useParams()
    const [player, loading, error] = useDocument(firestore.doc(`players/${id}`),{})

    return (
        <Box className={'player-container'}>
            <Box className={"player"}>
                {(!loading && !error) && <Live player={player.data()}/>}
            </Box>
        </Box>
    )
}
