import React from "react";
import {Box, CircularProgress, Grid} from "@mui/material";
import {PlayerCard} from "./PlayerCard";

export const PlayersGrid = ({players, archived}) => {
    return (
        <Box>
            <Grid container spacing={4}>
                {players.docs.filter(d =>d.data().archived === archived).map((p) =>
                    <Grid key={p.id} item xs={12} md={6} lg={4}>
                        <PlayerCard id={p.id} player={p.data()}/>
                    </Grid>
                )}
            </Grid>
        </Box>

    )
}
