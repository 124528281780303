import React, {useState} from "react";
import {Box, Card, CardContent, Collapse, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {CopyBlock, dracula, monokai} from "react-code-blocks";

const languages = ['Jsx', 'Html']

const code = (url) => {
    return ({
        html: `<div style="padding:56.25% 0 0 0; position:relative;">
    <iframe src="${url}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute; top:0; left:0; width:100%; height:100%;">
    </iframe>
</div>`,
        jsx: `<div style={{position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', width:'100%'}} >
    <iframe
        src={"${url}"}
        allowFullScreen
        style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, border: "none"}}></iframe>
</div>`
    })
}

export const Embed = ({player}) => {
    const [language, setLanguage] = useState('html')

    return (
        <Box>
            <Card elevation={5}>
                <CardContent>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Linguaggio</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            label="Linguaggio"
                        >
                            {languages.map(l =>
                                <MenuItem key={l} value={l.toLowerCase()}>{l}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <Collapse in={language !== ''}>
                        <Box sx={{mt: 2}}>
                            <CopyBlock
                                text={language !== '' ? code(`https://players.plesh.co/${player.id}`)[language] : ''}
                                language={language}
                                theme={dracula}
                                codeBlock
                                showLineNumbers={false}
                            />
                        </Box>
                    </Collapse>
                </CardContent>
            </Card>
        </Box>
    )
}
