import React, {useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import axios from "axios";
import {API_URL} from "../../const";

export const AddPlayer = ({open, handleSetOpen, handleSetMessage, handleSetPostError}) => {
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    const [service, setService] = useState('')
    const [stream_url, setStreamUrl] = useState('')
    const [sending, setSending] = useState(false)

    const resetValues = () => {
        setName('')
        setService('')
        setId('')
        setStreamUrl('')
    }
    const addPlayer = async (e) => {
        e.preventDefault()
        const data = {name, id, service, stream_url}
        setSending(true)
        await axios.post(`${API_URL}/players`, data)
            .then(() => {
                resetValues()
                handleSetOpen(false)
            })
            .catch((e) => {
                handleSetPostError(true)
                handleSetMessage(e.response.data.message)
            })
        setSending(false)
    }

    const handleChangeNome = (value) => {
        let v = value
        setName(value)

        setId(v.toLowerCase()
            .replace(/[',:"]+/g, '')
            .replace(/\s+/g, '-')
            .slice(0, 200))
    }
  return(
      <Dialog open={open} onClose={() => handleSetOpen(false)} maxWidth={'sm'} fullWidth>
          <DialogContent>
              <Typography textAlign={'center'} fontWeight={'bold'} variant={"h5"} sx={{pb: 2}}>
                  Crea un player
              </Typography>
              <form onSubmit={addPlayer}>
                  <Stack spacing={2}>
                      <TextField required variant={'standard'} label={'Nome'}
                                 onChange={(e) => handleChangeNome(e.target.value)}/>
                      <TextField required value={id} variant={'standard'} label={'Id'}
                                 onChange={(e) => setId(e.target.value)}
                                 helperText={`https://player.plesh.co/${id}`}
                      />
                      <FormControl fullWidth variant={"standard"}>
                          <InputLabel id={'demo-simple-select-label'}>Servizio</InputLabel>
                          <Select
                              label={'Servizio'}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) => setService(e.target.value)}
                          >
                              <MenuItem value={'ivs'}>Ivs</MenuItem>
                              <MenuItem value={'multilanguage'}>Multilingua</MenuItem>
                          </Select>
                      </FormControl>
                      <TextField required variant={'standard'} label={'URL Stream'}
                                 onChange={(e) => setStreamUrl(e.target.value)}/>
                      <Box sx={{textAlign: 'center', pt: 3}}>
                          {sending ? <CircularProgress/> :
                              <Button variant={"contained"} type={'submit'}>aggiungi</Button>}
                      </Box>
                  </Stack>
              </form>
          </DialogContent>
      </Dialog>
  )
}
