import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette:{
        mode:'dark',
        primary: {
            main:'#e6fc69'
        }
    }
})
