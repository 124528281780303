import React, {useState} from "react";
import {
    Box,
    Button,
    Card, CircularProgress,
    Container,
    Dialog,
    DialogContent,
    Grid,
    Modal,
    Stack,
    TextField, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import {PlayersGrid} from "../components/Home/PlayersGrid";
import HomeAppBar from "../components/shared/HomeAppBar";
import axios from "axios";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {AddPlayer} from "../components/Home/AddPlayer";
import {API_URL} from "../const";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Home = () => {
    const [open, setOpen] = useState(false)
    const [players, loading, error] = useCollection(firestore.collection('players').orderBy("created_at", "desc"), {})
    const [success, setSuccess] = useState(false)
    const [postError, setPostError] = useState(false)
    const [message, setMessage] = useState('')
    const [archived, setArchived] = useState(false)

    const handleChangeArchived = (event, newArchived) => {
        setArchived(newArchived);
    };

    const handleSetOpen = (bool) => {
        setOpen(bool)
    }

    const handleSetPostError = (bool) => {
        setPostError(bool)
    }

    const handleSetMessage = (m) => {
        setMessage(m)
    }

    return (
        <Box>
            <Snackbar open={postError} autoHideDuration={6000} onClose={() => setPostError(!postError)}>
                <Alert onClose={() => setPostError(!postError)} severity="error" sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(!success)}>
                <Alert onClose={() => setSuccess(!success)} severity="success" sx={{width: '100%'}}>
                    Player aggiunto correttamente
                </Alert>
            </Snackbar>
            <HomeAppBar page={'home'} handleSetOpen={handleSetOpen}/>
            <AddPlayer open={open} handleSetOpen={handleSetOpen} handleSetMessage={handleSetMessage} handleSetPostError={handleSetPostError}/>
            <Container sx={{height: '100%', py: 4, display: 'block'}}>
                <Box sx={{pb: 2}}>
                    <ToggleButtonGroup
                        value={archived}
                        exclusive
                        onChange={handleChangeArchived}
                        aria-label="Platform"
                    >
                        <ToggleButton value={false}>Attivi</ToggleButton>
                        <ToggleButton value={true}>Archiviati</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                {(!error && !loading) && <PlayersGrid players={players} archived={archived}/>}
            </Container>
        </Box>
    )
}
