import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import {registerIVSTech, registerIVSQualityPlugin} from 'amazon-ivs-player';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
const wasmWorkerPath = 'ivs/amazon-ivs-wasmworker.min.js'
const wasmBinaryPath = 'ivs/amazon-ivs-wasmworker.min.wasm'
const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();

export const VideoJS = ({options, onReady, streamUrl, service, live}) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    if (service === 'ivs') {
        registerIVSTech(videojs, {
            wasmWorker: createAbsolutePath(wasmWorkerPath),
            wasmBinary: createAbsolutePath(wasmBinaryPath),
        });
    }

    React.useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                if (service === 'ivs') {
                    registerIVSQualityPlugin(videojs);
                    player.enableIVSQualityPlugin();
                }

                player.src(streamUrl);
                player.hlsQualitySelector({
                    displayCurrentQuality: true
                });
                player.play();

                onReady && onReady(player);
            });
        }
        // eslint-disable-next-line
    }, [options, videoRef, streamUrl, live]);

    return (
        <div data-vjs-player>
            <video ref={videoRef} className='video-js vjs-big-play-centered'
                           style={{width: '100%', height: '100%'}}></video>
        </div>
    );
}

export default VideoJS;
