import React, {useEffect, useState} from "react";
import {Box, Button, Card, CircularProgress, Container, Stack, TextField, Typography} from "@mui/material";
import logo from '../assets/logo-players.png'
import {firebase, auth} from "../firebase/clientApp";
import {useNavigate} from "react-router-dom";

export const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const logIn = async (e) => {
        e.preventDefault()
        setLoading(true)
        await auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                navigate('/', {replace: true})
            })
            .catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
            });
        setLoading(false)
    }

    return (
        <Box sx={{textAlign: 'center', width: '100%'}}>
            <Container maxWidth={'sm'}>
                <Box sx={{width: 'min(100%, 20rem)', margin:'auto', pt:15, pb:10}}>
                    <img src={logo} style={{width: '100%'}}/>
                </Box>
                <Card sx={{p: 4}}>
                    <Typography variant={"h5"} fontWeight={'bold'} textAlign={'center'}>Login</Typography>
                    <form style={{padding: '1rem 1rem', textAlign: 'left'}} onSubmit={logIn}>
                        <Stack direction={"column"} spacing={4}>
                            <TextField variant={'standard'} onChange={(e) => setEmail(e.target.value)} label={'Email'}/>
                            <TextField type='password' variant={'standard'}
                                       onChange={(e) => setPassword(e.target.value)} label={'Password'}/>
                            <Box sx={{textAlign: 'center'}}>
                                {loading ?
                                    <CircularProgress/>
                                    :
                                    <Button sx={{my: 2}} variant={"contained"} type={"submit"}>
                                        entra
                                    </Button>
                                }
                            </Box>
                        </Stack>
                    </form>
                </Card>
            </Container>
        </Box>
    )
}
