import React from "react";
import {Box, Typography} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Tappi} from "./Tappi";
import {Parameters} from "./Parameters";
import {Analytics} from "./Analytics";
import {Embed} from "./Embed";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const Options = ({player}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  return(
      <Box>
          <Typography variant={"h5"} fontWeight={'bold'}>
              Impostazioni
          </Typography>
          <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="wrapped label tabs example"
                  >
                      <Tab label="Tappi" {...a11yProps(0)} />
                      <Tab label="Parametri" {...a11yProps(1)} />
                      <Tab label="Embed" {...a11yProps(2)} />
                      <Tab label="Analytics (coming soon)" {...a11yProps(3)} disabled/>
                  </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                  <Tappi player={player}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                  <Parameters player={player}/>
              </TabPanel>
              <TabPanel value={value} index={2}>
                  <Embed player={player}/>
              </TabPanel>
              <TabPanel value={value} index={3} disabled>
                  <Analytics/>
              </TabPanel>
          </Box>
      </Box>
  )
}
