import React, {useRef} from 'react';
import videojs from 'video.js';
import VideoJS from './VideoJS'
import {Box} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import {useParams} from "react-router-dom";

const Live = ({player}) => {
    const {id} = useParams()
    const playerRef = useRef(null);

    const [poster, loading, error] = useDocument(firestore.doc(`players/${id}/posters/${player.active_poster}`))

    const videoJsOptions = {
        autoplay: true,
        audioOnlyMode: player.audio_only || false,
        inactivityTimeout: player.audio_only ? 0 : undefined,
        controls: true,
        responsive: true,
        fluid: true,
        techOrder: player.service==='ivs' ? ["AmazonIVS"] : ["html5"],
        poster: (poster && !loading && !error) ? poster.data().link : ''
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
    };

    return (
        <Box className={"player"}>
            {(player.live) ?
                <VideoJS live={player.live} service={player.service} options={videoJsOptions} onReady={handlePlayerReady}
                         streamUrl={player.stream_url}/>
                :
                !error && !loading && <div style={{width: '100%', height: '100%'}}>
                    <img src={poster.data().link} style={{width: '100%', height: '100%'}}/>
                </div>
            }
        </Box>
    );
}

export default Live;
