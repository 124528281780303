import React from "react";
import {Box} from "@mui/material";

export const Analytics = () => {
    return(
        <Box>
            mi piacerebbe veramente molto
        </Box>
    )
}
