import React, {useState} from "react";
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import {Check, Delete, Upload} from "@mui/icons-material";
import {FileUploader} from "react-drag-drop-files";
import {API_URL} from "../../const";
import axios from "axios";

export const Tappi = ({player}) => {
    const {id} = useParams()
    const [posters, loading, error] = useCollection(firestore.collection(`players/${id}/posters`))
    const [uploading, setUploading] = useState(false)
    const [activating, setActivating] = useState(false)

    const [file, setFile] = useState(null)
    const handleUploadTappo = (file) => {
        setFile(file)
    };

    const uploadPoster = async () => {
        const formData = new FormData();
        formData.append('file', file);
        setUploading(true)
        await axios({
            url: `${API_URL}/players/${id}/posters`,
            method: 'POST',
            data: formData,
        }).then(() => setFile(null))
        setUploading(false)
    }

    const activatePoster = async (poster_id) => {
        setActivating(true)
        await axios.put(`${API_URL}/players/${id}/activate-poster`, {poster_id})
            .then()
        setActivating(false)
    }

    const deletePoster = async (poster_id) => {
        setActivating(true)
        await axios.put(`${API_URL}/players/${id}/delete-poster`, {poster_id})
            .then()
        setActivating(false)
    }

    return (
        <Box>
            <Grid container spacing={2} justifyContent={'start'} alignItems={'stretch'}>
                {(!loading && !error) &&
                    posters.docs.map(p =>
                        <Grid item xs={12} md={6}>
                            <Card sx={{
                                height: '100%',
                                border: '1px solid',
                                borderColor: 'divider',
                                background: (p.id === player.data().active_poster) ? 'rgba(0,255,0,0.10)' : ''
                            }}>
                                <CardMedia>
                                    <img src={p.data().link} style={{width: '100%'}}/>
                                </CardMedia>
                                <CardContent>
                                    <Typography textAlign={'center'}>
                                        {p.data().link.split('/').slice(-1)[0]}
                                    </Typography>
                                    {(p.id === player.data().active_poster) ?
                                        <Typography variant={"h6"} textAlign={'center'} sx={{pt: 2}}>
                                            Attivo
                                        </Typography>
                                        :
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={'center'}
                                               sx={{pt: 2, position: 'relative'}}>
                                            {activating &&
                                                <Stack sx={{position: 'absolute', width: '100%', height: '100%'}}
                                                       justifyContent={"center"} alignItems={"center"}>
                                                    <CircularProgress size={30}/>
                                                </Stack>}
                                            <Box>
                                                <Button disabled={activating} variant={"contained"}
                                                        onClick={() => activatePoster(p.id)}>
                                                    attiva
                                                </Button>
                                            </Box>
                                            {p.data().link.split('/').slice(-1)[0] !== 'tappo_generico_plesh.png' &&
                                                <IconButton disabled={activating} color={'error'}
                                                            onClick={() => deletePoster(p.id)}>
                                                    <Delete/>
                                                </IconButton>}
                                        </Stack>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }

            </Grid>
            <Divider sx={{my: 5}}/>
            <Stack spacing={2} sx={{width: '100%'}}>
                <FileUploader
                    multiple={false}
                    handleChange={handleUploadTappo}
                    name="file"
                    types={['jpg', 'png']}
                    children={
                        <Box sx={{
                            width: '100%',
                            p: 2,
                            border: '2px dashed white',
                            borderRadius: '1rem',
                            textAlign: 'center'
                        }}>
                            <Stack direction={'row'} justifyContent={'center'} alignItems={"center"} spacing={4}>
                                {!file ? <Upload sx={{fontSize: '3rem'}}/> :
                                    <Check sx={{fontSize: '3rem', color: 'green'}}/>}
                                <Typography
                                    variant={"body1"}>{!file ? "Clicca per scegliere un'immagine o trascinala qui" : "Caricata:" + ' ' + file.name}</Typography>
                            </Stack>
                        </Box>
                    }
                />
                {uploading ?
                    <Box sx={{textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    : <Button variant={"contained"} disabled={!file} onClick={uploadPoster}>
                        carica tappo
                    </Button>}
            </Stack>
        </Box>
    )
}
